import React from 'react';
import { Button, Form, FormFeedback, FormGroup, Input, Label, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import "react-datetime/css/react-datetime.css";

export const FormDoctor = (props) => {

    const {
        toggle, loading,
        specialties, id_specialty, name, professional_license,
        phone, address, email, handleInputChange,
        methodAction, button,
        nameInputs,validaciones1, 
        addSpecialty, name_specialty, validaciones, isCommercialPartner
    } = props;
    const {
        id_specialty: validacionIdSpecialty,
        name: validacionName,
        name_specialty: validacionNameSpecialty
        
    } = validaciones;
    const {
        name: nameInput,
        id_specialty: id_specialtyInput,
        professional_license: professional_licenseInput,
        phone: phoneInput,
        address: addressInput,
        email: emailInput,
        // isCommercialPartner: isCommercialPartner
    } = nameInputs;

    // ////console.log(validacionIdSpecialty);
    // ////console.log("pasa aqui");
    // ////console.log(validaciones1);
    // ////console.log("pasa aqui");


    return (
        <Form className="" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <FormGroup className="mb-3 col-6">
                        <Label className="col-form-label" >Nombre del médico todos <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input
                            placeholder="Nombre del médico"
                            type="text"
                            name={nameInput}
                            value={name} 
                            required={true}
                            autoComplete="nope"
                            onChange={handleInputChange}
                            invalid={validacionName}
                            className="form-control form-control-sm input-air-primary"
                        />
                        <FormFeedback>El nombre debe tener 3 o más caracteres</FormFeedback>
                    </FormGroup>
                    <FormGroup className="mb-3 col-6 p-1">
                        <Label className="col-form-label">Especialidad <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input
                            type="select"
                            name={id_specialtyInput}
                            value={id_specialty}
                            required={true}
                            onChange={handleInputChange} 
                            invalid={validacionIdSpecialty || validaciones1}
                            className="form-control form-control-sm input-air-primary"

                        >
                            <option value={0}>Selecciona una especialidad</option>
                            {
                                addSpecialty !== undefined 
                                // &&
                                // <option value="new">NO ESPECIFICADA</option>
                            }
                            {
                                specialties.length > 0 &&
                                specialties.map((x, key) => {
                                    return <option
                                        key={key}
                                        value={x.id_specialty}
                                    >
                                        {x.name}
                                    </option>
                                })
                            }
                        </Input>
                        <FormFeedback>Selecciona una especialidad</FormFeedback>
                    </FormGroup>
                    <FormGroup className={`mb-3 col-12 ${(addSpecialty !== undefined && id_specialty === "new") ? "" : "d-none"}`}>
                        <Label className="text-sm">Nombre de la nueva especialidad <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input
                            // placeholder="Nueva especialidad"
                            type="text"
                            name="name_specialty"
                            className="form-control form-control-sm input-air-primary"
                            value={name_specialty}
                            required={false}
                            autoComplete="off"
                            onChange={handleInputChange}
                            invalid={validacionNameSpecialty}
                        />
                        <FormFeedback>La especialidad debe tener 3 o más caracteres</FormFeedback>
                    </FormGroup>
                    <FormGroup className="mb-3 col-6">
                        <Label className="text-sm">Licencia profesional </Label>
                        <Input
                            // placeholder="Licencia profesional"
                            type="text"
                            name={professional_licenseInput}
                            value={professional_license}
                            autoComplete="off"
                            onChange={handleInputChange}
                            className="form-control form-control-sm input-air-primary"

                        />
                    </FormGroup>
                    <FormGroup className="mb-3 col-6">
                        <Label className="text-sm">Télefono </Label>
                        <Input
                            // placeholder="Télefono"
                            type="text"
                            name={phoneInput}
                            value={phone}
                            autoComplete="off"
                            onChange={handleInputChange}
                            className="form-control form-control-sm input-air-primary"

                        />
                    </FormGroup>
                    <FormGroup className="mb-3 col-6">
                        <Label className="text-sm">Dirección </Label>
                        <Input
                            type="text"
                            name={addressInput}
                            value={address}
                            autoComplete="off"
                            onChange={handleInputChange}
                            className="form-control form-control-sm input-air-primary"

                        />
                    </FormGroup>
                    <FormGroup className="mb-3 col-6">
                        <Label className="text-sm">Correo electrónico</Label>
                        <Input
                            type="text"
                            name={emailInput}
                            value={email}
                            autoComplete="off"
                            onChange={handleInputChange}
                            className="form-control form-control-sm input-air-primary"
                        />
                    </FormGroup>
                    <Col md="3" style={{ textAlign: "center" }} className="p-l-0" >
                        <FormGroup>
                            <Label className="col-form-label">¿Cuenta con Convenio? </Label>
                            <br /> <br />
                            <Label className="switch">
                                <Input type="checkbox" name="isCommercialPartner" onChange={handleInputChange} checked={isCommercialPartner} />
                                <span className="slider round"></span>
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    // color="primary"
                    outline color="primary"
                    type="submit"
                    size="sm"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    {button}
                </Button>
                <Button
                    size="sm"
                    className="btn-air-light"
                    outline color="danger"
                    type="button"
                    onClick={toggle}
                >
                    Cancelar
                </Button>
            </ModalFooter>
        </Form>
    )
}
