import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import { Button, Collapse, Label, Row, CardHeader, Col, Card, CardBody } from "reactstrap";
import { CKEditor } from 'ckeditor4-react';
import { useGlobalReferenceValue } from '../../../hooks/profiles/useGlobalReferenceValue';

export const IndexGlobalReferenceValues = (props) => {

    const {
        profileId
    } = props;

    const {
        globalReferenceValue, handleChangeGlobalReference, saveGlobalReferenceValue, configCkEditor
    } = useGlobalReferenceValue(profileId);
    return (
        <>
            <Col className="default-according style-1 faq-accordion job-accordion position-relative" id="accordionoc" sm="12">
                <Row className="position-sticky" style={{ top: 140 }}>
                    <Col xl="12">
                        <Card className="form theme-form">
                            <CardHeader className=' bg-secondary '>
                                <h5 className="mb-0">
                                    <Button color="link pl-0" data-toggle="collapse"
                                        data-target="#collapseicon" aria-controls="collapseicon" ><i className="icofont icofont-atom f-22 "></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Valores de Referencia</Button>
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm="12">
                                        <p><span className='text-info'>*</span> Si deseas agregar una imagen, solo selecciona tu imagen de tu galeria <code>(ctrl + c)</code>, posteriomente pega la imagen en el editor <code>( ctrl + v )</code> </p>
                                    </Col>
                                </Row>
                                <Row className='m-t-2'>
                                    <Col sm="12" md="12" lg="12" xl="12">
                                        {
                                            globalReferenceValue !== '' &&
                                            <CKEditor
                                                initData={globalReferenceValue}
                                                data={globalReferenceValue}
                                                type="classic"
                                                onChange={handleChangeGlobalReference}
                                                config={configCkEditor}
                                                editorUrl={'//cdn.ckeditor.com/4.20.0/full/ckeditor.js'}
                                            />
                                        }

                                    </Col>
                                </Row>
                                <Row className='m-t-15'>
                                    <Col sm="4" md="4" lg="4" xl="4"></Col>
                                    <Col sm="4" md="4" lg="4" xl="4">
                                        <Button
                                            outline
                                            color='primary'
                                            type='button'
                                            onClick={() => saveGlobalReferenceValue()}
                                        >
                                            Guardar
                                        </Button>
                                    </Col>
                                    <Col sm="4" md="4" lg="4" xl="4"></Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </>
    )
}