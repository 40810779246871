import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';

export const useGlobalReferenceValue = (examId) => {

    const [idExam, setIdExam] = useState(examId);
    const history = useHistory();

    //SHOW DISPLAY MESSAGE
    const [createSweet, sweetConfirmation, sweetDelete, sweetError, createSweett, sweetConfirmationUpdate, sweetConfirmRepeatExam, sweetConfirmationChangeClient] = useSweetAlert();
    //END SHOW DISPLAT MESSAGE

    //#region CONFIG CKEDITOR
    const [configCkEditor, setConfigCkEditor] = useState({
        toolbarGroups: [
            { name: "document", groups: ["mode", "document", "doctools"] },
            {
                name: "editing",
                groups: ["find", "selection", "spellchecker", "editing"]
            },
            { name: "forms", groups: ["forms"] },
            { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
            {
                name: "paragraph",
                groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"]
            },
            {
                name: "alignment",
                groups: ["justify", "left", "center", "right"]
            },
            "/",
            { name: "links", groups: ["links"] },
            { name: "insert", groups: ["insert"] },
            { name: "styles", groups: ["styles"] },
            { name: "colors", groups: ["colors"] },
            { name: "tools", groups: ["tools"] },
            "/",
            { name: "clipboard", groups: ["clipboard", "undo"] },
            { name: "others", groups: ["others"] },
            { name: "about", groups: ["about"] }
        ],
        // extraPlugins: "autoembed,embedsemantic,image2,mathjax,codesnippet,font",
        removeButtons:
            "Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting,Undo,Redo",
        fontSize_sizes: "6/6px;8/8px;10/10px;12/12px;14/14px;16/16px;24/24px;48/48px;",
        font_names:
            "Arial/Arial, Helvetica, sans-serif;" +
            "Times New Roman/Times New Roman, Times, serif;" +
            "Verdana",
        //allowedContent: true,
        // disableNativeSpellChecker: false
        // skin: "moono",
        // plugins:
        //   "dialogui,dialog,about,a11yhelp,dialogadvtab,basicstyles,bidi,blockquote,notification,button,toolbar,clipboard,panelbutton,panel,floatpanel,colorbutton,colordialog,templates,menu,contextmenu,copyformatting,div,resize,elementspath,enterkey,entities,popup,filetools,filebrowser,find,fakeobjects,flash,floatingspace,listblock,richcombo,font,forms,format,horizontalrule,htmlwriter,iframe,wysiwygarea,image,indent,indentblock,indentlist,smiley,justify,menubutton,language,link,list,liststyle,magicline,maximize,newpage,pagebreak,pastetext,pastefromword,preview,print,removeformat,save,selectall,showblocks,showborders,sourcearea,specialchar,scayt,stylescombo,tab,table,tabletools,tableselection,undo,lineutils,widgetselection,widget,notificationaggregator,uploadwidget,uploadimage,wsc"
    })
    //#endregion

    //#region GLOBAL REFERENCE VALUE
    //#region GLOBAL REFERENCE VALUE MODEL
    const [formGlobalReference, handleInputChangeGlobalReference, resetGlobal, handleUpdateValuesGlobalReference, handleSelectValuesGlobalReference, handlePickerValuesGlobalReference] = useForm({
        globalReferenceValue: ""
    });

    const { globalReferenceValue } = formGlobalReference;

    //#endregion

    //#region GLOBAL REFERENCE VALUE USE EFFECTS
    useEffect(() => {

        if (idExam > 0) {
            getGlobalReferenceValue(examId);
        }
    }, []);


    //#endregion


    //#region GLOBAL REFERENCE VALUE METHODS
    const getGlobalReferenceValue = async (id_exam) => {
        //console.log(id_exam);
        let listTmp = await handleRequest("GET", `Exam/GlobalReferenceValue/${id_exam}`, "Referencia global");
        //console.log(listTmp);

        if (listTmp !== null) {

            handleUpdateValuesGlobalReference({
                ...formGlobalReference,
                globalReferenceValue: listTmp.reference_value
            });
        }
    }

    const handleChangeGlobalReference = (e) => {
        let data = e.editor.getData();
        handlePickerValuesGlobalReference(data, "globalReferenceValue");
    }

    const saveGlobalReferenceValue = async () => {
        if (formGlobalReference.globalReferenceValue.length > 5) {
            let body = {
                id_exam: idExam,
                reference_value: globalReferenceValue
            };

            let listTmp = await handleRequest("POST", "Exam/SaveGlobalReferenceValue", "Referencia global", body);

            if (listTmp !== null) {
                createSweet("create", "success", "Exito!");
            }
        }
    }





    const deleteGlobalReferenceValue = async () => {
        
        if (formGlobalReference.globalReferenceValue.length > 5) {
            let a =  idExam
    
            let listTmp = await handleRequest("PUT", `Exam/ClearGlobalReferenceValue/${a}`, "Referencia global");

            if (listTmp !== null) {
                createSweet("create", "success", "Exito!");
                window.location.reload()
            }
            else {
                createSweet("warning", "warning", "No se realizo acción!");


            }
        }
        
    }

    // saveGlobalReferenceValue
    //#endregion
    //#endregion

    //GLOBAL REQUEST
    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }

    return {
        globalReferenceValue, handleChangeGlobalReference, saveGlobalReferenceValue, configCkEditor,deleteGlobalReferenceValue,formGlobalReference
    }
}
